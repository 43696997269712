<template>
	<main-container :showHeader="false">
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item-sites v-model="queryForm.websites" :abbr="false"></form-item-sites>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">搜 索</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="downloadTask">下 载</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item-channels v-model="queryForm.gaChannels"></form-item-channels>
					<form-item label="选择日期:" style="width:330px;" :tips="['日期最大选择范围为60天']" :tipWidth="160">
						<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="timestamp" style="width:250px;" size="mini" :clearable="false" :picker-options="pickerOptions">
						</el-date-picker>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table height="auto" :data="tableData">
				<el-table-column label="Day" min-width="150">
					<template slot-scope="scope">
						{{scope.row.costDate|formatDate('YYYY-MM-DD', false)}}
					</template>
				</el-table-column>
				<el-table-column label="站点" prop="website.code" min-width="150">
				</el-table-column>
				<el-table-column label="Channel" prop="channel" min-width="150">
				</el-table-column>
				<el-table-column label="ga_channel" prop="gaChannel" min-width="150">
				</el-table-column>
				<el-table-column label="Account" prop="accountName" min-width="150">
				</el-table-column>
				<el-table-column label="Account ID" prop="accountId" min-width="150">
				</el-table-column>
				<el-table-column label="Campaign Name" prop="campaignName" min-width="150">
				</el-table-column>
				<el-table-column label="Campaign ID" prop="campaignId" min-width="150">
				</el-table-column>
				<el-table-column label="Adset Name" prop="adSetName" min-width="150">
				</el-table-column>
				<el-table-column label="Adset ID" prop="adSetId" min-width="150">
				</el-table-column>
				<el-table-column label="Country" prop="country" min-width="100" align="center">
				</el-table-column>
				<el-table-column label="Cost" prop="cost" min-width="100" align="center">
				</el-table-column>
				<el-table-column label="Rev" prop="revenue" min-width="100" align="center">
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import page from '@/common/mixins/page.js'
	import common from '@/common/mixins/common.js'
	import {
		mapActions,
		mapState
	} from 'vuex'
	export default {
		mixins: [page, common],
		inject: ['adminLayout'],
		components: {
		},
		data() {
			return {
				firstChosenDate: null,
				pickerOptions: {
					disabledDate:(date) => {
						if (!this.firstChosenDate) return false
						const deltaDays = 59
						const lessThan = Number(Moment(date).startOf('day').format('x')) < Number(Moment(this.firstChosenDate).startOf('day').add(-1 * deltaDays, 'day').format('x'))
						const greaterThan = Number(Moment(date).startOf('day').format('x')) > Number(Moment(this.firstChosenDate).startOf('day').add(deltaDays, 'day').format('x'))
						return lessThan || greaterThan
					},
					onPick: ({minDate, maxDate}) => {
						if (!maxDate) {
							this.firstChosenDate = minDate
						} else {
							this.firstChosenDate = null
						}
					}
				},
				queryForm: {
					websites: [],
					gaChannels: [],
					date: [
						Moment().startOf('day').add(-7, 'days').format('x'),
						Moment().endOf('day').add(-1, 'days').format('x')
					]
				},
				q: {
					createdMinStr: Moment().startOf('day').add(-7, 'days').format('YYYY-MM-DD'),
					createdMaxStr: Moment().endOf('day').add(-1, 'days').format('YYYY-MM-DD')
				},
				tableData: []
			}
		},
		computed: {
			...mapState({
				sources: state => state.dataset.sources
			})
		},
		methods: {
			...mapActions([
				'fetchDailyForms',
				'exportRecords'
			]),
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				return this.fetchDailyForms({
					params: {
						...this.page,
						...this.q
					},
					trigger
				})
			},
			handleList(content) {
				this.tableData = content
			},
			// Button Events
			searchEvent() {
				this.q = {
					createdMinStr: Moment(Number(this.queryForm.date[0])).format('YYYY-MM-DD'),
					createdMaxStr: Moment(Number(this.queryForm.date[1])).format('YYYY-MM-DD'),
					websites: this.queryForm.websites,
					gaChannels: this.queryForm.gaChannels
				}
				this.page.current = 1
				this.getList()
			},
			downloadTask() {
				this.$store.commit('download-manager/addTask', {
					action: `exportRecords`,
					payload: {...this.q},
					title: `Records(${this.q.createdMinStr}~${this.q.createdMaxStr})`,
					dispatch: this.$store.dispatch,
					responseType: 'blob'
				})
			}
		}
	}
</script>

<style lang="scss">

</style>
